import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

export const Section = styled.section`
  padding: 1.5rem 3rem 0;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  &:nth-child(even) {
    background-color: #E9ECEF;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  max-width: 1300px;
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 30px;

  @media screen and (min-width: 788px) {
    width: 48%
  }
`;

export const Title = styled.div`
  width: 100%;
  text-align:center;
  font-weight: bold;
  background-color: #24558A;
  padding: 6px;
  color: white;
`;

export const Form = styled.form`
  width: 100%;
  border-radius: 5px;
  padding: 20px;
`;

export const Span = styled.span`
  color:rgb(204,33,33);
  margin-right: 5px;
`;

export const Label = styled.label`
  display: flex;
  flex-wrap: wrap;
`;

export const Input = styled.input`
  width: 100%;
  padding: 9px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
`;

export const Button = styled.button`
  width: 50%;
  cursor:pointer;
  background-color: #24558A;
  color: white;
  padding: 14px 20px;
  margin: 8px 25% 0;
  border: none;
  border-radius: 4px;
`;

interface ModalProps {
  active: Boolean;
}
export const Modal = styled.div<ModalProps>`
  display: ${({ active }) => (active ? 'block' : 'none')};;
  position: fixed;
  z-index: 100000;
  padding-top: 400px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
`;

export const ModalContent = styled.div`
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
`;

export const Image = styled(GatsbyImage)`
  width: 100%;
  margin: auto;
  border-radius: 30px 30px 30px 30px;
  border: 3px solid #24558A;
`;
