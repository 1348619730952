/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from 'react';
import { graphql, HeadFC, useStaticQuery } from 'gatsby';
import axios from 'axios';
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import Seo from '../components/seo';
import Layout from '../components/layout';
import {
  Section, Wrapper, Container, Title, Span, Input, Form, Label, Button, Modal, ModalContent, Image,
} from '../styles/pagos.elements';

const Payments = () => {
  const dataQuery = useStaticQuery(graphql`
    query {
      contentfulPages(title: {eq: "Pagos"}) {
        title
        images {
          gatsbyImageData
          id
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const { images } = dataQuery.contentfulPages;
  const url = dataQuery.site.siteMetadata.siteUrl;

  const formRef = React.useRef(null);

  const [active, setActive] = React.useState(false);
  const [isSubmited, setIsSubmited] = React.useState(false);
  const [ids, setIds] = React.useState({ merchant: '', accountId: '' });
  const [data, setData] = React.useState({
    buyerFullName: '', referenceCode: '', buyerEmail: '', description: '', amount: '', billingCity: '', tax: '', signature: '',
  });

  const handleChange = (event: any) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setActive(true);

    const ref = event.target.referenceCode.value;

    try {
      const referenceCode = event.target.referenceCode.value + String(Math.floor(Math.random() * 100000));
      const amount = event.target.amount.value;
      const tax = ((amount / 1.19) * 0.19).toFixed(2);
      const res = await axios.post(`${url}/hash.php`, { referenceCode, amount });
      if (!res.data || !res.data.merchantId || !res.data.accountId || !res.data.signature) throw new Error();
      setIds({ merchant: res.data.merchantId, accountId: res.data.accountId });
      setData({
        ...data, referenceCode, tax, signature: String(res.data.signature),
      });
      setIsSubmited(true);
    } catch (error) {
      setData({ ...data, referenceCode: ref });
      setActive(false);
      setIsSubmited(false);
    }
  };

  React.useEffect(() => {
    if (isSubmited) {
      setIsSubmited(false);
      (formRef.current as any).submit();
    }
  }, [isSubmited]);

  return (
    <Layout>
      <main>
        <Section>
          <Wrapper>
            <Container>

              <Modal active={active}>
                <ModalContent>
                  <p>Cargando..</p>
                </ModalContent>
              </Modal>
              <Title>
                Informacion Pago
              </Title>

              <Form onSubmit={handleSubmit} method="post" action="https://checkout.payulatam.com/ppp-web-gateway-payu" ref={formRef}>
                <Label><Span>*</Span>Nombre o Razon social
                  <Input
                    name="buyerFullName"
                    type="text"
                    placeholder="Nombre o razon social del cliente"
                    required
                    onChange={handleChange}
                    value={data.buyerFullName || ''}
                  />
                </Label>

                <Label><Span>*</Span>Identificacion
                  <Input
                    name="referenceCode"
                    type="text"
                    placeholder="Identificacion del cliente"
                    required
                    onChange={handleChange}
                    value={data.referenceCode || ''}
                  />
                </Label>

                <Label><Span>*</Span>Correo
                  <Input
                    name="buyerEmail"
                    type="email"
                    placeholder="Correo electronico"
                    required
                    onChange={handleChange}
                    value={data.buyerEmail || ''}
                  />
                </Label>

                <Label><Span>*</Span>Concepto
                  <Input
                    name="description"
                    type="text"
                    placeholder="Concepto del Pago"
                    required
                    onChange={handleChange}
                    value={data.description || ''}
                  />
                </Label>

                <Label><Span>*</Span>Total a pagar
                  <Input
                    name="amount"
                    type="number"
                    placeholder="Total incluido Iva"
                    required
                    onChange={handleChange}
                    value={data.amount || ''}
                  />
                </Label>

                <Label><Span>*</Span>Ciudad
                  <Input
                    name="billingCity"
                    type="text"
                    placeholder="Ciudad"
                    required
                    onChange={handleChange}
                    value={data.billingCity || ''}
                  />
                </Label>

                <input name="merchantId" type="hidden" value={ids.merchant || ''} />
                <input name="accountId" type="hidden" value={ids.accountId || ''} />
                <input name="tax" type="hidden" value={data.tax || ''} />
                <input name="taxReturnBase" type="hidden" value="0" />
                <input name="currency" type="hidden" value="COP" />
                <input name="signature" type="hidden" value={data.signature || ''} />
                <input name="test" type="hidden" value="0" />
                <input name="responseUrl" type="hidden" value="https://www.electriredes.com/" />
                <input name="confirmationUrl" type="hidden" value="https://www.electriredes.com/" />
                <Button type="submit">Pagar</Button>
              </Form>
            </Container>

            <Container>
              <Image image={getImage(images[0]) as IGatsbyImageData} alt="Metodos de pago" />
            </Container>
          </Wrapper>
        </Section>
      </main>
    </Layout>
  );
};

export default Payments;

export const Head: HeadFC = () => <Seo title="Pagos" />;
